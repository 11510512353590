//Importamos axios para peticiones XMLHttpRequest
import axios from "axios";
import router from "@/router";
import store from '@/store'

/* INSTANCIANDO AXIOS Y SU CONFIG INICIAL */
/*****************************************/
const service = axios.create({
    baseURL: process.env.BACKEND_URI || "/api",
    timeout: 5000, // Timeout de la peticion
    headers: {
        "Content-type": "application/json"
    }
});

//Interceptor de peticiones axios
service.interceptors.request.use(
    config => {
        //Logica de comprobación de peticiones
        //INSERT CODE HERE
        return config
    },
    error => {
        //Aquí no se suele añadir nada
        console.log("Error de petición");
        console.log(error);
        return Promise.reject(error)
    }
);

//Interceptor de respuestas axios
service.interceptors.response.use(
    response => {
        // CÓDIGO: 2000 == La operación es correcta y se mostrará el TOAST
        // CÓDIGO: 2001 == La operación es correcta y no se mostrará el TOAST
        // CÓDIGO: 3000 == La operación no ha salido correctamente y se mostrará TOAST
        // CÓDIGO: 4000 == La ha sido bloqueada por un administrador y se mostrará TOAST

        // Personalizamos el código para saber que las credenciales no son
        // válidas:: es distinto al 401 que lo usaremos para redirigir al logout
        // para borrarle el token y redirigirlo al login automaticamente
        if ((response.data.code === 2000)) {
            store.dispatch("toast/showMessage", {
                message: response.data.message,
                style: "success"
            });
            // Devolvemos el objeto de los datos
            // quitandole las cabeceras, config, etc
            return response.data
        } else if ((response.data.code === 2001)) {
            // Devolvemos el objeto de los datos
            // quitandole las cabeceras, config, etc
            return response.data
        } else if ((response.data.code === 3000)) {
            store.dispatch("toast/showMessage", {
                message: response.data.message,
                style: "error"
            });
            // Devolvemos el objeto de los datos
            // quitandole las cabeceras, config, etc      
            return response.data
        } else if ((response.data.code === 4000)) {
            store.dispatch("toast/showMessage", {
                message: response.data.message,
                style: "error"
            });
            // Devolvemos el objeto de los datos
            // quitandole las cabeceras, config, etc      
            return response.data
        }
        else {
            // Devolvemos la respeusta para que siga su flujo
            // en caso de que no exista ningún codigo que nos interese
            // se devolverán cabeceras, config, data, ==> todo
            return response
        }

    },
    error => {
        // 401 Unauthorized: Peticion denegada
        if (error.response.status === 401) {
            store.dispatch("toast/showMessage", {
                message: error.response.data.message,
                style: "error",
            });
            router.push({ name: "logout" });
        }

        //400 Bad Request: Peticion malformada
        if (error.response.status === 400) {
            store.dispatch("toast/showMessage", {
                message: error.response.data.message,
                style: "error",
            });
        }

        // 500 Internal Server Error: Error servidor
        if (error.response.status === 500) {
            store.dispatch("toast/showMessage", {
                message: "Error en la comunicación con la API",
                style: "error",
            });
        }

        // 502 Bad Gateway: Si conexión al servidor
        if (error.response.status === 502) {
            store.dispatch("toast/showMessage", {
                message: "Error en la comunicación con la API",
                style: "error",
            });

            return Promise.reject(error)
        }
    }
);

/* EXPORTACIÓN PUBLICA */
export default service